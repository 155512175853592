<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Edit Perizinan",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Edit Perizinan",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Perizinan",
                    href: "/master/perizinan",
                },
                {
                    text: "Edit Perizinan",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel options data
            optionsEselon: [],
            optionsJenisPerizinan: [],

            // variable Page
            mp_id: this.$route.params.mp_id,
            mp_nama: null,
            pilihan_jenis_perizinan: null,
            mp_jenis_sk: null,
            eselon_selected: null,
        };
    },
    mounted() {
        let self = this;
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        // load data perizinan
        var config_ref_perizinan = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-perizinan",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_ref_perizinan)
            .then(function (response) {
                var response_data_fix = response.data.data.referensi;
                self.optionsJenisPerizinan = response_data_fix;
            })
            .catch(function (error) {
                console.log(error);
            });
        // load data eselon
        var config_ref_eselon = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-eselon",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_ref_eselon)
            .then(function (response) {
                var response_data_fix = response.data.data.referensi;
                self.optionsEselon = response_data_fix;
                Swal.close();
            })
            .catch(function (error) {
                console.log(error);
                Swal.close();
            });
        // get data perizinan berdasarkan id
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan",
            params: {
                mp_id: self.mp_id,
            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if (response_data.meta.code == 200) {
                    var data_edit = response_data_fix.data[0];
                    self.mp_nama = data_edit.mp_nama;
                    self.pilihan_jenis_perizinan = data_edit.perizinanjenis;
                    self.mp_jenis_sk = data_edit.mp_jenis_sk;

                    Swal.close();
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.close();
            });
    },
    methods: {
        // Method dari masing masing fungsi di dalam file ini
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan",
                data: {
                    mp_id: self.mp_id,
                    mp_nama: self.mp_nama,
                    mp_mpj_id: self.pilihan_jenis_perizinan?.mpj_id,
                    mp_jenis_sk: self.mp_jenis_sk,
                    eselon_id: self.eselon_selected?.me_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master perizinan segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-perizinan" });
                        }
                    });
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Pilh Jenis Perizinan" label-for="formrow-pilihan-jenis-perizinan-select">
                                        <v-select id="formrow-pilihan-jenis-perizinan-select" :options="optionsJenisPerizinan" label="mpj_nama" v-model="pilihan_jenis_perizinan" placeholder="Pilih Jenis Perizinan"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Pilhan Eselon" label-for="formrow-pilihan-eselon-select">
                                        <v-select id="formrow-pilihan-eselon-select" :options="optionsEselon" label="me_nama" v-model="eselon_selected" placeholder="Pilih Eselon"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Nama Perizinan" label-for="formrow-nama-perizinan-input">
                                        <b-form-input id="formrow-nama-perizinan-input" type="text" v-model="mp_nama"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12" v-if="pilihan_jenis_perizinan?.mpj_id == 2">
                                    <b-form-group class="mb-3" label="Pilih Layot Surat Keputusan" label-for="formrow-mp_jenis_sk">
                                        <v-select id="formrow-mp_jenis_sk" :options="['Surat Keputusan', 'SK Pengantar']" v-model="mp_jenis_sk" placeholder="Pilih Layot Surat Keputusan"></v-select>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
